import React from 'react'
import './contact.css'

const Contact = () => {
  return (
    <section id='contact'>
      Contact
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container contact__container">
          <h5>Email</h5>
          <br></br>
          <h2>rhn210000@utdallas.edu</h2>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <h5 className="text-light">Thank you for checking out my page!</h5>
          </div>
    </section>
  )
}

export default Contact